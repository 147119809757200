import SessionCache from '@plugins/cache';

const SET_HAS_PENDING_OPERATIONS = 'SET_HAS_PENDING_OPERATIONS';
const cache = new SessionCache('signatures');

export default {
	namespaced: true,

	state() {
		return { numberOfPendingOperations: 0 };
	},

	mutations: {
		[SET_HAS_PENDING_OPERATIONS](state, value) {
			state.numberOfPendingOperations = value;
		},
	},

	actions: {
		fetch(
			{ commit, dispatch },
			{ status, paginationKey, refresh, ebContract }
		) {
			const cacheKey = `list/${status}`;
			const queryParams = {};
			const url = '/signature';
			const method = 'GET';

			const statusAux =
				status === 'pending' ? 'pending|SIGNED_ON_BACKOFFICE' : status;

			/* istanbul ignore next */
			if (refresh) {
				SessionCache.clear('signatures');
			}

			if (cache.has(cacheKey) && !paginationKey) {
				return cache.get(cacheKey);
			}

			if (statusAux) {
				Object.assign(queryParams, { status: statusAux.toUpperCase() });
			}

			if (paginationKey) {
				Object.assign(queryParams, { paginationKey });
			}

			if (ebContract) {
				Object.assign(queryParams, { ebContract });
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
				},
				{ root: true }
			).then(({ data }) => {
				// If cache and new items, we add them to the end of the list
				if (cache.has(cacheKey)) {
					const result = cache.get(cacheKey);
					data?.data?.forEach((item) => {
						const found = result.data.find(
							({ signatureId }) => signatureId === item.signatureId
						);

						/* istanbul ignore else */
						if (!found) {
							result.data.push(item);
						}
					});

					Object.assign(data?.data, result.data);
				}

				/* istanbul ignore else */
				if (status === 'pending' || status === 'Pending') {
					commit('SET_HAS_PENDING_OPERATIONS', data?.data?.length);
				} else {
					cache.set(cacheKey, data);
				}
				return data;
			});
		},

		async get({ dispatch }, { type, signatureId }) {
			let signature = cache.get(`item/${type}/${signatureId}`);

			/* istanbul ignore else */
			if (!signature) {
				const signatures = await dispatch('fetch', { status: type });
				signature = signatures.data.find(
					({ signatureId: itemId }) => itemId === signatureId
				);
			}

			/* istanbul ignore next */
			if (!signature?.signatureId) {
				return Promise.reject();
			}

			return signature;
		},

		sign({ dispatch }, { type, signatureId }) {
			const url = `/signature/${signatureId}`;
			const method = 'PUT';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then((res) => {
				SessionCache.clear('signatures');
				SessionCache.clear('bizum');
				return dispatch('fetch', { status: type }).then(() => res);
			});
		},

		delete({ dispatch }, { type, signatureId }) {
			const url = `/signature/${signatureId}`;
			const method = 'DELETE';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(() => SessionCache.clear('signatures'))
				.then(() => dispatch('fetch', { status: type }));
		},

		complete({ dispatch }, { type, signatureId }) {
			const url = `/signature/${signatureId}`;
			const method = 'PATCH';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(() => SessionCache.clear('signatures'))
				.then(() => dispatch('fetch', { status: type }));
		},
	},
};
