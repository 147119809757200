import SessionCache from '@plugins/cache';

const cache = new SessionCache('marketplace');
const products = new SessionCache('products');
const productKey = 'items';

export default {
	namespaced: true,

	actions: {
		getCategories({ dispatch }, language) {
			const url = '/marketplace/categories';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams: language,
				},
				{ root: true }
			).then(({ data }) => data);
		},

		getOffers({ dispatch }, { paginationKey, refresh, language }) {
			const url = '/marketplace/offers';
			const method = 'GET';
			const queryParams = { language };

			const cacheKey = 'marketplace/offers';

			/* istanbul ignore next */
			if (refresh) {
				SessionCache.clear('marketplace');
			}

			/* istanbul ignore else */
			if (cache.has(cacheKey) && !paginationKey) {
				return cache.get(cacheKey);
			}

			/* istanbul ignore else */
			if (paginationKey) {
				Object.assign(queryParams, { paginationKey });
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
				},
				{ root: true }
			).then(({ data }) => {
				const response = {
					data: data.data,
					paging: data.paging,
				};

				if (cache.has(cacheKey)) {
					const cachedData = cache.get(cacheKey);

					Object.assign(
						response.data,
						cachedData.data.concat(response.data)
					);
				}

				cache.set(cacheKey, response);

				return response;
			});
		},

		getProducts(
			{ dispatch },
			{ paginationKey, language, category, refresh } = {}
		) {
			const url = '/marketplace/products';
			const method = 'GET';
			const queryParams = {
				language,
				category,
			};

			const cacheKey = `marketplace/products/${category}`;

			/* istanbul ignore else */
			if (refresh) {
				SessionCache.clear('marketplace');
			}

			/* istanbul ignore else */
			if (cache.has(cacheKey) && !paginationKey) {
				return cache.get(cacheKey);
			}

			/* istanbul ignore else */
			if (paginationKey) {
				Object.assign(queryParams, { paginationKey });
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
				},
				{ root: true }
			).then(({ data }) => {
				const response = {
					data: data.data,
					paging: data.paging,
				};

				if (cache.has(cacheKey)) {
					const cachedData = cache.get(cacheKey);

					Object.assign(
						response.data,
						cachedData.data.concat(response.data)
					);
				}

				cache.set(cacheKey, response);

				return response;
			});
		},

		getDetails({ dispatch }, { productId, language }) {
			const url = `/marketplace/products/${productId}`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams: { language },
				},
				{ root: true }
			).then(({ data: { data } }) => data);
		},

		getComments({ dispatch }, { productId, paginationKey } = {}) {
			const url = `/marketplace/products/${productId}/reviews`;
			const method = 'GET';
			const queryParams = {};

			const cacheKey = url;

			if (cache.has(cacheKey) && !paginationKey) {
				return cache.get(cacheKey);
			}

			if (paginationKey) {
				Object.assign(queryParams, { paginationKey });
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
				},
				{ root: true }
			).then(({ data }) => {
				const response = {
					data: data.data,
					paging: data.paging,
				};

				if (cache.has(cacheKey)) {
					const cachedData = cache.get(cacheKey);

					Object.assign(
						response.data,
						cachedData.data.concat(response.data)
					);
				}

				cache.set(cacheKey, response);

				return response;
			});
		},

		initProductRequest(
			{ dispatch },
			{ productId, relatedProductId, depositAmount, interveners, transfer }
		) {
			const url = `/marketplace/products/${productId}/request`;
			const method = 'POST';
			const payload = {
				relatedProductId,
				depositAmount,
			};

			if (transfer) {
				payload.transfer = transfer;
			}

			/* istanbul ignore else */
			if (interveners?.length) {
				Object.assign(payload, { interveners });
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				},
				{ root: true }
			).then(({ data: { data } }) => {
				SessionCache.clear('products');
				products.clear(productKey);

				return data;
			});
		},

		getProductRequestStatus({ dispatch }, requestId) {
			const url = `/marketplace/requests/${requestId}`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(({ data }) => data)
				.catch(() => {});
		},

		validateProduct({ dispatch }, requestId) {
			const url = `/marketplace/requests/${requestId}/validate`;
			const method = 'POST';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => data);
		},
	},
};
