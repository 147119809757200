import { typesByTitle } from '@modules/products/product-types';
import { subtypesById } from '@modules/products/product-subtypes';
import productFamilies from '@modules/products/product-families';
import SessionCache from '@plugins/cache';
import categorizeProducts from '@modules/products/product-sort';
import resourcesModule from '@modules/resources/m-resources';

const cache = new SessionCache('products');
const cacheKey = 'items';
const byProfileId = (profileId, products) =>
	products.filter(
		({ profiles }) => profiles && profiles.find(({ id }) => id === profileId)
	);

export default {
	namespaced: true,

	modules: { resources: resourcesModule },

	actions: {
		fetch({ dispatch, rootState }, { refresh, force } = {}) {
			const profileId = rootState.profiles?.defaultProfile?.id;

			if (!refresh && cache.has(cacheKey)) {
				const productsCache = cache.get(cacheKey);

				if (profileId && !force) {
					return byProfileId(profileId, productsCache);
				}

				return productsCache;
			}

			const url = '/products';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(async ({ data: { data } }) => {
				/* istanbul ignore next */
				if (!data?.length) {
					return [];
				}

				const collection = categorizeProducts(data);
				const mPortfolioType = typesByTitle['managed-portfolio'];
				const mRSIPortfolioType = typesByTitle['managed-rsi-portfolio'];
				const promises = collection
					.filter(({ productType: { id } }) =>
						[mPortfolioType, mRSIPortfolioType].includes(id)
					)
					.map(async ({ id: productId, profiles }) => {
						const products = await dispatch('getPortfolio', {
							productId,
						});
						const normalizedProducts = products.map((item) => ({
							...item,
							parentId: productId,
							productSubtype: { id: `m-${item.productSubtype.id}` },
							relationType: { id: item?.relationType?.id ?? '01' },
							profiles,
						}));

						return categorizeProducts(normalizedProducts);
					});
				const managedProducts = await Promise.all(promises);

				/* istanbul ignore next */
				if (managedProducts?.length) {
					collection.push(...managedProducts.flat());
				}

				await cache.set(cacheKey, collection);

				if (profileId) {
					return byProfileId(profileId, collection);
				}

				return collection;
			});
		},

		async get({ dispatch }, id) {
			let products = cache.get(cacheKey);

			/* istanbul ignore next */
			if (!products?.length) {
				products = await dispatch('fetch');
			}

			/* istanbul ignore next */
			if (id?.includes('/')) {
				const [productId] = id.split('/');
				return products.find(({ id: itemId }) => itemId === productId);
			}

			const product = products.find(({ id: itemId }) => itemId === id);

			/* istanbul ignore next */
			if (!product) {
				return Promise.reject();
			}

			return product;
		},

		getCreditors({ dispatch }, { productId }) {
			const url = `/products/${productId}/direct-debits/creditors`;
			const method = 'GET';
			const key = `creditors/${productId}`;

			if (cache.has(key)) {
				return cache.get(key);
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => {
				cache.set(key, data);
				return data;
			});
		},

		getCreditCardScps({ dispatch }, { productId }) {
			const url = `/products/${productId}/scps`;
			const method = 'GET';
			const key = `scps/${productId}`;

			if (cache.has(key)) {
				return cache.get(key);
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => {
				cache.set(key, data);
				return data;
			});
		},

		getSingleProduct({ dispatch }, { productId }) {
			const url = `/products/${productId}`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			);
		},

		getDirectDebits({ dispatch }, { paginationKey, productId, creditorId }) {
			const url = `/products/${productId}/direct-debits/creditors/${creditorId}/direct-debits`;
			const method = 'GET';
			const queryParams = {};

			if (paginationKey) {
				Object.assign(queryParams, { paginationKey });
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
				},
				{ root: true }
			).then(({ data }) => data);
		},

		getDirectDebitsDetail(
			{ dispatch },
			{ productId, creditorId, directDebitId }
		) {
			const url = `/products/${productId}/direct-debits/creditors/${creditorId}/direct-debits/${directDebitId}`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => data);
		},

		deleteDirectDebit({ dispatch }, { productId, idDelete }) {
			const url = `/products/${productId}/direct-debits/creditors/${idDelete}`;
			const method = 'DELETE';
			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			);
		},

		getPosition({ dispatch }, { productId }) {
			const url = `/products/${productId}/position`;
			const method = 'GET';
			const key = `position/${productId}`;

			if (cache.has(key)) {
				return cache.get(key);
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => {
				cache.set(key, data);
				return data;
			});
		},

		getInterveners({ dispatch }, productId) {
			const url = `/products/${productId}/interveners`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => data);
		},

		getMovementDetail(
			{ dispatch },
			{ productId, movementId, movementCoreId, typeId }
		) {
			const url = `/products/${productId}/movements/${movementId}`;
			const method = 'GET';

			const key = `/product/${productId}/debit/${movementId}`;

			if (cache.has(key)) {
				return cache.get(key);
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: {
						movementCoreId,
						typeId,
					},
				},
				{ root: true }
			)
				.then(({ data }) => {
					cache.set(key, data);
					return cache.get(key);
				})
				.catch(() => {});
		},

		getPortfolio({ dispatch }, { productId }) {
			const url = `/products/${productId}/managedProducts`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data: { data } }) => data);
		},

		getCardCVV({ dispatch }, { productId }) {
			const url = `/products/${productId}/cvv`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data: { cvv } }) => cvv);
		},

		getCardPIN({ dispatch }, { productId }) {
			const url = `/products/${productId}/pin`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data: { pin } }) => pin);
		},

		getCardPAN({ dispatch }, { productId }) {
			const url = `/products/${productId}/pan`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data: { pan } }) => pan);
		},

		byService({ dispatch }, byService) {
			const url = '/products';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams: { byService },
				},
				{ root: true }
			).then(({ data: { data } }) => {
				/* istanbul ignore next */
				if (!data?.length) {
					return;
				}

				return categorizeProducts(data);
			});
		},

		byCoreType({ dispatch }, { byCoreType, byRelationType }) {
			const url = '/products';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams: {
						byCoreType,
						byRelationType,
					},
				},
				{ root: true }
			).then(({ data: { data } }) => {
				/* istanbul ignore next */
				if (!data?.length) {
					return;
				}

				return categorizeProducts(data);
			});
		},

		getDetails({ dispatch }, id) {
			if (id.includes('/')) {
				const parts = id.split('/');
				const [productId, resource, resourceId] = parts;

				return dispatch(
					'resources/get',
					{
						resource,
						productId,
						resourceId,
					},
					{ root: true }
				);
			}

			const key = `detail/${id}`;

			/* istanbul ignore next */
			if (cache.has(key)) {
				return cache.get(key);
			}

			const url = `/products/${id}`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => {
				cache.set(key, data);

				return data;
			});
		},

		/* istanbul ignore next */
		async getSiblings({ dispatch, rootState }, productId) {
			const profileId = rootState.profiles.defaultProfile?.id;
			let products = cache.get(cacheKey);

			if (!products?.length) {
				products = await dispatch('fetch');
			}

			if (profileId) {
				products = byProfileId(profileId, products);
			}

			const categorizedProducts = products.reduce((reducer, item) => {
				const productSubtype = subtypesById[item.productSubtype.id];
				const families = Object.entries(productFamilies);
				const familyGroup = families.find(([, group]) =>
					group.includes(productSubtype)
				);

				if (familyGroup) {
					const [familyName] = familyGroup;

					if (!reducer[familyName]) {
						// eslint-disable-next-line no-param-reassign
						reducer[familyName] = [];
					}

					reducer[familyName].push(item);
				}

				return reducer;
			}, {});

			const [, familyMembers] = Object.entries(categorizedProducts).find(
				([, group]) => group.find(({ id }) => id === productId)
			);

			return familyMembers;
		},

		getSimulateSettlement({ dispatch }, productId) {
			const url = `/products/${productId}/simulate-settlement`;
			const method = 'POST';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => data);
		},

		putAlias({ dispatch }, { alias, productId }) {
			const url = `/products/${productId}/alias`;
			const method = 'PUT';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { alias },
				},
				{
					root: true,
				}
			).then(() => {
				const getCache = cache.get(cacheKey);

				getCache?.forEach((item, index) => {
					/* istanbul ignore next */
					if (item.id === productId) {
						getCache[index].alias = alias;
					}
				});
			});
		},

		getReceipt(
			{ dispatch },
			{ productId, movementId, query = {}, reportType = 'pdf' }
		) {
			const urlDetail = `/products/${productId}/movements/${movementId}/document`;
			const urlList = `/products/${productId}/movements/document`;
			const method = 'GET';
			const url = movementId ? urlDetail : urlList;

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams: {
						reportType,
						...query,
					},
				},
				{ root: true }
			).then(({ data: { content } }) => content);
		},

		getCertificate({ dispatch }, { productId, movementId, query = {} }) {
			const url = `/products/${productId}/movements/${movementId}/transferCertificate`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams: { ...query },
				},
				{ root: true }
			).then(({ data: { content } }) => content);
		},

		getHolderCertificate({ dispatch }, { productId }) {
			const url = `/products/${productId}/holderCertificate`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data: { content } }) => content);
		},

		getCardLimits({ dispatch }, { productId }) {
			const url = `/products/${productId}/limits`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => data);
		},

		activateCard({ dispatch }, { productId }) {
			const url = `/products/${productId}/cardActivation`;
			const method = 'PUT';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then((data) => {
				return data;
			});
		},

		blockCard({ dispatch }, { productId, cardBlockType }) {
			const url = `/products/${productId}/cardBlock`;
			const method = 'PUT';
			const payload = {};

			/* istanbul ignore else */
			if (cardBlockType) {
				Object.assign(payload, { cardBlockType });
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				},
				{ root: true }
			);
		},

		changeCardLimit({ dispatch }, { productId, ...params }) {
			const url = `/products/${productId}/changeCardLimit`;
			const method = 'PUT';
			const payload = { ...params };

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				},
				{ root: true }
			).then((data) => {
				return data;
			});
		},

		blockCardTemporally({ dispatch }, { productId, activate }) {
			const url = `/products/${productId}/temporalyCardBlock`;
			const method = 'PUT';
			const payload = { activate };

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				},
				{ root: true }
			);
		},

		ssoBolsaCaminos({ dispatch }) {
			const url = '/products/sso/bolsacaminos';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then((data) => data);
		},
		ssoBolsaCaminosAssisted({ dispatch }, userId) {
			const url = `/products/sso/bolsacaminos/${userId}/profile`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then((data) => data);
		},
		modifyBolsaCaminosAssisted({ dispatch }, { userId, status }) {
			const url = `/products/sso/bolsacaminos/${userId}/profile`;
			const method = 'PATCH';
			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: status,
				},
				{ root: true }
			).then((data) => data);
		},
		bolsaCaminos({ dispatch }) {
			const url = '/products/bolsacaminos';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then((data) => data);
		},
	},
};
