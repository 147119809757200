export default {
	namespaced: true,

	actions: {
		getSignatureState({ dispatch }, { processId }) {
			const url = `/documents/${processId}/signature`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			);
		},
	},
};
